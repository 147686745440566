<template>
  <div class="mx-a mb-16">
    <div class="content-section mb-16" style="margin-top: 10px">
      <div class="flex-space-between" style="padding: 24px">
        <div>
          <div class="flex-align-center">
            <div class="content-circle"></div>
            <span class="content-info"
              >결제항목은 파트너 회원의 전문 활동분야와 관련된 항목만
              등록가능하며, 관련없는 내용을 등록 시 본사에서 확인을 요청할 수
              있습니다.</span
            >
          </div>
          <div class="flex-align-center mt-8">
            <div class="content-circle"></div>
            <span class="content-info"
              >모든 결제항목 등록 및 수정 시 매달 본사의 승인 후 등록되며,
              미승인 시 매달 앱에 노출되지 않습니다.</span
            >
          </div>
          <div class="flex-align-center mt-8">
            <div class="content-circle"></div>
            <span class="content-info"
              >결제항목 등록신청 관련 문의사항은 파트너 고객센터(카카오채널)로
              문의주시기 바랍니다.</span
            >
          </div>
        </div>
        <div class="content-use-button">이용방법 보기</div>
      </div>
    </div>
    <!-- 카테고리 -->
    <div class="content-section mb-16" style="margin-top: 10px">
      <div class="content-section-header">
        <div class="d-flex">
          <span class="fs-16">카테고리</span>
          <span class="fs-16" style="color: #f1431d">*</span>
        </div>
      </div>
      <div class="content-section-content">
        <div>
          <span class="fs-14"
            >선택한 카테고리 : {{ getHighCateName }}
            <span v-if="getHighCateName !== ''">></span>
            <span style="color: #2CCDC3">{{ getCateName }}</span></span
          >
        </div>
        <div class="mt-16 d-flex">
          <div class="content-category-select">
            <div
              :class="`content-category-select-item${
                sendParams.highCategoryEsntlId === highCate.esntlId
                  ? '-active'
                  : ''
              }`"
              v-for="(highCate, index) in highCateList"
              :key="'highCate' + index"
              @click="selectHighCate(highCate.esntlId)"
            >
              {{ highCate.title }}
            </div>
          </div>
          <img
            class="content-category-arrow"
            src="@/assets/image/arrow_right_green.svg"
            alt="arrow"
          />
          <div class="content-category-select">
            <div
              :class="`content-category-select-item${
                sendParams.lowCategoryEsntlId === cate.esntlId ? '-active' : ''
              }`"
              v-for="(cate, index) in cateList"
              :key="'cate' + index"
              @click="selectCate(cate)"
            >
              {{ cate.title }}
            </div>
          </div>
        </div>
        <p class="fs-12 mt-16" style="color: #2CCDC3">
          전문 활동분야와 맞지 않는 카테고리에 등록할 경우 본사에서 확인을
          요청할 수 있습니다.
        </p>
        <p class="fs-12 mb-16" style="color: #2CCDC3">
          관련분야를 선택해주세요.
        </p>
      </div>
    </div>
    <!-- 코스제목 -->
    <div class="content-section mb-16" style="margin-top: 10px">
      <div class="content-section-header">
        <div class="d-flex">
          <span class="fs-16">결제 항목 설정</span>
          <span class="fs-16" style="color: #f1431d">*</span>
        </div>
      </div>
      <div class="content-section-content">
        <div class="mt-8 mb-8">
          <div class="d-flex align-items-center">
            <input-radio
              v-model="titleType"
              val-nm="NEW"
              wpCls="mr-64"
              style="min-width: 85px"
              >신규</input-radio
            >
            <input-radio
              v-model="titleType"
              val-nm="ING"
              wpCls="mr-64"
              style="min-width: 85px"
              >등록중</input-radio
            >
            <input-radio
              v-model="titleType"
              val-nm="LAST"
              wpCls="mr-64"
              style="min-width: 85px"
              >마지막 신청</input-radio
            >
          </div>
        </div>
        <button-common
          class="mt-32"
          :size="SIZE_T"
          clr="w"
          @click="addPaymentGroup"
        >
          + 추가
        </button-common>
        <div class="course-wrap">
          <draggable v-model="grpArr" handle=".grp-bars">
            <transition-group>
              <payment-grp
                v-for="(v, i) in grpArr"
                :key="v.id"
                :item="v.payment"
                :name="`${v.name} ${i + 1}`"
                @remove="onRemoveGrp(v.id)"
                @input="onInputPayment($event, i)"
                group="group"
              />
            </transition-group>
          </draggable>
        </div>
      </div>
    </div>

    <!-- 아래 버튼 -->
    <div class="fixed d-flex align-items-center flex-end under-finish">
      <!-- <div class="warning fr-12 mr-32">
        <slot />
      </div> -->
      <div v-if="isCheckAdd">
        <button-common :size="SIZE_T" :clr="CLR_W" wp-cls="mr-8"
          >미리보기</button-common
        >
        <button-common :size="SIZE_T" :clr="CLR_W" wp-cls="mr-8" @click="goPage"
          >취소</button-common
        >
        <button-common
          :disabled="!isCheckAdd || !checkValid"
          :size="SIZE_T"
          :clr="CLR_M"
          @click="doReg"
          >신청하기</button-common
        >
      </div>
      <div v-else>
        <span class="error mr-32">입점계약 이후 결제항목 등록이 가능합니다.</span>
        <button-common
          :size="SIZE_T"
          :clr="CLR_M"
          style="width: 150px"
          @click="goContractPage"
          >계약신청하러가기</button-common
        >
      </div>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
import PaymentGrp from "@/components/ui/payment/PaymentGrp";

export default {
  name: "PaymentAdd",
  mixins: [],
  components: {
    draggable,
    PaymentGrp,
  },

  /*** data ***/
  data() {
    return {
      isCheckAdd: false,
      titleType: "NEW",
      highCateList: [],
      cateList: [],
      grpArr: [],
      sendParams: {
        highCategoryEsntlId: "",
        lowCategoryEsntlId: "",
        serviceList: [],
      },
      isReset: false,
      toRoute: {
        path: "",
        meta: "",
      },
    };
  },

  /*** created ***/
  created() {
    this.ntDt = this._.cloneDeep(this.$data);
  },

  /*** mounted ***/
  async mounted() {
    await this.checkAdd();
    await this.getHighCategoryList();
  },

  // beforeRouteLeave(to, from, next) {
  //   this.toRoute.path = to.fullPath;
  //   this.toRoute.meta = to.meta.id;
  //   if (this.isReset) {
  //     next();
  //   } else {
  //     next(false);
  //     if (!this.isReset) {
  //       this.onPageReset();
  //     }
  //   }
  // },

  /*** computed ***/
  computed: {
    ...mapGetters(["G_FOLDER_ID"]),
    infoMsg() {
      return this.msg !== "" ? this.msg : this.MSG_FORM_EMPTY;
    },
    getHighCateName() {
      let highCateList = this.highCateList.filter(
        (item) => this.sendParams.highCategoryEsntlId === item.esntlId
      );
      return highCateList.length > 0 ? highCateList[0].title : "";
    },
    getCateName() {
      let cateList = this.cateList.filter(
        (item) => this.sendParams.lowCategoryEsntlId === item.esntlId
      );
      return cateList.length > 0 ? cateList[0].title : "";
    },
    checkValid() {
      return (
        this.grpArr.filter((item) => item.payment.name.length > 0).length > 0 &&
        this.sendParams.highCategoryEsntlId &&
        this.sendParams.lowCategoryEsntlId
      );
    },
  },

  /*** watch ***/
  watch: {
    titleType() {
      if (this.titleType === "NEW") {
        this.resetParams();
      }

      if (this.titleType === "LAST") {
        this.getLastData();
      }

      if (this.titleType === "ING") {
        this.getIngData();
      }
    },
  },

  /*** methods ***/
  methods: {
    /** @ 계약 내역 체크 ***/
    async checkAdd() {
      const rs = await this.$axios({
        url: "/partner/service/check",
        params: {},
      });
      if (rs.result === "SUC") {
        this.isCheckAdd = true;
      } else {
        this.isCheckAdd = false;
        await this.$openConfirm(
          {
            bodyTx:
              "입점계약 이후 등록이 가능합니다.<br/>아래 버튼을 눌러 계약신청해주세요.",
            btnScndNm: "닫기",
            btnPrmyNm: "신청하러가기",
            btnSize: this.SIZE_S,
            cbPrmy: this.goContractPage,
            data: {},
          },
          this
        );
      }
    },

    /** @ 마지막 신청 조회 ***/
    async getLastData() {
      const rs = await this.$axios({
        url: "/partner/service/last/data",
        params: {},
      });
      if (rs.result === "SUC") {
        this.setData(rs.resultData);
        console.log("마지막 신청 조회 완료");
      } else {
        console.log("마지막 신청 조회 실패");
      }
    },

    /** @ 등록중 신청 조회 ***/
    async getIngData() {
      const rs = await this.$axios({
        url: "/partner/service/reg/data",
        params: {},
      });
      if (rs.result === "SUC") {
        this.setData(rs.resultData);
        console.log("등록중 조회 완료");
      } else {
        console.log("등록중 조회 실패");
      }
    },

    /** @ 상세조회 데이터 세팅 ***/
    setData(data) {
      if (data) {
        this.sendParams.highCategoryEsntlId = data.highCategoryEsntlId;
        this.sendParams.lowCategoryEsntlId = data.lowCategoryEsntlId;
        // 서비스 리스트 순서 정렬
        this.grpArr = [];
        data.serviceList.forEach((item) => {
          const content = JSON.parse(JSON.stringify(item));
          content.itemList.sort((a, b) => {
            return a.numberOrder - b.numberOrder;
          });
          this.grpArr.push({
            id: this.$nnid(),
            name: `항목`,
            payment: content,
          });
        });
        this.grpArr.sort((a, b) => {
          return a.payment.numberOrder - b.payment.numberOrder;
        });
      }
    },

    /** @ 카테고리 상위키 조회 ***/
    async getHighCategoryList() {
      const rs = await this.$axios({
        url: "/specialty",
        params: { highEsntlId: "" },
      });
      if (rs.result === "SUC") {
        this.highCateList = rs.resultList;
        if (this.sendParams.highCategoryEsntlId) {
          this.selectHighCate(this.sendParams.highCategoryEsntlId);
        }
        console.log("상위 카테고리 조회 완료");
      } else {
        console.log("상위 카테고리 조회 실패");
      }
    },

    /** @ 카테고리 하위키 조회 ***/
    async selectHighCate(id) {
      this.sendParams.highCategoryEsntlId = id;
      const rs = await this.$axios({
        url: "/specialty",
        params: { highEsntlId: id },
      });
      if (rs.result === "SUC") {
        this.cateList = rs.resultList;
        console.log("하위 카테고리 조회 완료");
      } else {
        console.log("하위 카테고리 조회 실패");
      }
    },

    selectCate(cate) {
      this.sendParams.lowCategoryEsntlId = cate.esntlId;
    },

    /** @ 등록 신청 ***/
    async doReg() {
      this.sendParams.serviceList = [];
      this.grpArr.forEach((item, index) => {
        const payment = JSON.parse(JSON.stringify(item.payment));
        payment.numberOrder = index;
        payment.itemList.forEach((data, dataIndex) => {
          data.numberOrder = dataIndex;
        });
        if (payment.name.length > 0) {
          this.sendParams.serviceList.push(payment);
        }
      });
      const rs = await this.$axios({
        url: "/partner/service/add",
        params: this.sendParams,
      });
      if (rs.result === "SUC") {
        this.resetParams();
        await this.openConfirm();
        console.log("등록 신청 완료");
      } else {
        console.log("등록 신청 실패");
      }
    },

    resetParams() {
      this.titleType = "NEW";
      this.grpArr = [];
      this.sendParams = {
        highCategoryEsntlId: "",
        lowCategoryEsntlId: "",
        serviceList: [],
      };
    },

    // 결제 그룹 추가
    addPaymentGroup() {
      const content = {
        name: "", // 이름
        type: "", // 유형
        price: "", // 할인전 금액
        minCnt: "", // 월 최소 횟수
        maxCnt: "", // 월 최대 횟수
        itemList: [], // 할인율 배열
        penalty: "", // 해지위약금
      };
      this.grpArr.push({ id: this.$nnid(), name: `항목`, payment: content });
    },

    /** @ 취소 ***/
    onPageReset() {
      // this.$openConfirm(
      //   {
      //     cbPrmy: this.goPage,
      //     bodyTx: "작성 중인 내용을 취소하시겠습니까?",
      //   },
      //   this
      // );
    },

    /**@ 모달 CB ***/
    // 모달콜백: 취소
    goPage() {
      this.isReset = true;
      this.$router.push({
        path: this.toRoute.path,
        meta: { id: this.toRoute.meta },
      });
    },

    goContractPage() {
      this.toRoute.path = "/contract/reg";
      this.toRoute.meta = "4-2-1";
      this.goPage();
    },

    // 그룹 삭제
    async onRemoveGrp(id) {
      if (this.grpArr.length < 2) {
        await this.$openAlert(
          {
            bodyTx:
              "마지막 남은 결제항목 1개는 삭제되지 않습니다.<br/>결제항목을 모두 삭제하시려면 본사에 요청해주세요.",
            btnScndNm: "닫기",
          },
          this
        );
      } else {
        await this.$openConfirm(
          {
            bodyTx: "해당 그룹을 삭제하시겠습니까?",
            cbPrmy: this.cbDeleteGrp,
            data: { id },
          },
          this
        );
      }
    },

    async openConfirm() {
      await this.$openAlert(
        {
          bodyTx:
            "등록신청 되었습니다.<br/>파트너 등록 담당자 확인 후 3 영업일 이내에 승인 됩니다.",
          btnScndNm: "닫기",
        },
        this
      );
    },

    // 모달콜백: 그룹 삭제
    cbDeleteGrp(val) {
      this.grpArr = this.grpArr.filter((item) => item.id !== val.id);
    },

    // 코스 데이터 입력시
    onInputPayment(payment, idx) {
      this.grpArr[idx].payment = payment;
    },
  },
};
</script>

<style scoped lang="scss">
.content-use-button {
  border: 1px solid #d8dae5;
  font-size: 12px;
  width: 100px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  color: #828699;
  cursor: pointer;
}
.content-circle {
  width: 3px;
  height: 3px;
  border-radius: 3px;
  background: #515466;
  margin-right: 5px;
}
.content-info {
  font-size: 12px;
  font-weight: 400;
  color: #515466;
}
.content-section-header {
  height: 53px;
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d8dae5;
}
.content-section-content {
  padding: 8px 32px;
}
.content-category-select {
  padding: 16px 16px 0 16px;
  width: 510px;
  height: 160px;
  border: 1px solid #d8dae5;
  overflow-y: scroll;
}
.content-category-select-item {
  width: 100%;
  height: 36px;
  padding: 8px;
  color: #828699;
  font-size: 14px;
  cursor: pointer;
}
.content-category-select-item-active {
  width: 100%;
  height: 36px;
  padding: 8px;
  color: #2CCDC3;
  font-size: 14px;
  cursor: pointer;
  background: #f1f2f8;
}
.content-category-arrow {
  margin-left: 40px;
  margin-right: 40px;
}
.course-wrap {
  padding: 16px 0;
}
.coach-table-null {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 320px;
  border: 1px solid #d8dae5;
}
.search-table-wraaper {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 320px;
  overflow-y: scroll;
}
.search-table-wraaper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.search-table-wraaper::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
.error {
  color: #f1431d;
  font-size: 14px;
}
</style>
